import React, { useContext } from 'react';
import { FlightsContext } from 'providers/FlightsProvider';

import styles from 'components/molecules/DetailsInfoWrapper/DetailsInfoWrapper.module.scss';
export const DetailsInfoWrapper = ({
  flightName,
  flightDate,
  flightState,
  flightCrew,
}) => {
  const context = useContext(FlightsContext);

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.infoWrapper__item}>
        <p>Lot: {flightName}</p>
        <p>Data lotu: {flightDate}</p>
        <p>Status: {flightState}</p>
      </div>
      <div className={styles.infoWrapper__item}>
        <p>Załoga: {flightCrew ? flightCrew : 0}</p>
        <p>Rakieta: {context.currentRocket.name}</p>
        <p>Start: {context.currentLaunchPad.name}</p>
      </div>
    </div>
  );
};

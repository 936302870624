import React, { useContext } from 'react';
import { FormField } from 'components/molecules/FormField/FormField';
import { Button } from 'components/atoms/Button/Button';
import { FlightsContext } from 'providers/FlightsProvider';
import { filterPredicates } from 'constans/constants';
import { DateRangePickerField } from 'components/molecules/DateRangePickerField/DateRangePickerField';
import { CheckBoxField } from 'components/molecules/CheckBoxField/CheckBoxField';

import styles from './Header.module.scss';

export const Header = () => {
  const context = useContext(FlightsContext);

  const handleChange = (e) => {
    const isDatepicker = e instanceof Array || e === null;
    if (isDatepicker) {
      context.setFilters((prevState) => ({ ...prevState, date: e }));
    } else {
      const { name, value, type, checked } = e.target;

      context.setFilters((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const activeFilters = Object.fromEntries(
      Object.entries(context.filters).filter(([key, value]) => !!value)
    );

    const filteredFlights = context.flights.filter((flight) => {
      return Object.entries(activeFilters).every(([key, value]) =>
        filterPredicates[key](flight, value)
      );
    });
    context.setFilteredFlights(filteredFlights);
  };

  return (
    <section className={styles.container}>
      <header className={styles.container__header}>
        <form onSubmit={onSubmit} className={styles.container__form}>
          <FormField
            textLabel="Nazwa lotu"
            type="text"
            placeholder="Wpisz nazwę"
            name="name"
            onChange={handleChange}
            value={context.filters.name}
          />
          <DateRangePickerField onChange={handleChange} />
          <CheckBoxField
            name="status"
            type="checkbox"
            onChange={handleChange}
            checked={context.filters.status}
          />
          <Button type="submit" text="Szukaj" />
        </form>
      </header>
    </section>
  );
};

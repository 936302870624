export const filterPredicates = {
  name: (flight, filterValue) =>
    flight.name.toLowerCase().includes(filterValue.toLowerCase()),
  status: (flight, filterValue) => flight.success === filterValue,
  date: (flight, filterValue) => {
    const flightDate = new Date(flight.date_local);

    return flightDate >= filterValue[0] && flightDate <= filterValue[1];
  },
};

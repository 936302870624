import React from 'react';

import styles from 'components/molecules/DescriptionWrapper/DescriptionWrapper.module.scss';
export const DescriptionWrapper = ({ description }) => {
  return (
    <div className={styles.descriptionsWrapper}>
      <p>{description ? description : 'brak opisu'}</p>
    </div>
  );
};

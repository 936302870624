import React from 'react';
import { Label } from 'components/atoms/Label/Label';
import { Input } from 'components/atoms/Input/Input';

import styles from 'components/molecules/FormField/FormField.module.scss';
export const FormField = ({
  textLabel,
  type,
  style,
  placeholder,
  styleInput,
  name,
  onChange,
  value,
  ...props
}) => {
  return (
    <Label
      textLabel={textLabel}
      htmlFor={name}
      style={style}
      className={styles.container}
    >
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        styleInput={styleInput}
        onChange={onChange}
        value={value}
        {...props}
      />
    </Label>
  );
};

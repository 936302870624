import React from 'react';
import { Header } from 'components/organisms/Header/Header';
import { RocketsLunchList } from 'components/organisms/RocketsLunchList/RocketLunchList';

export const HomePage = () => {
  return (
    <>
      <Header />
      <RocketsLunchList />
    </>
  );
};

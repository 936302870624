import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FlightsContext } from 'providers/FlightsProvider';

import nophoto from 'assets/nophoto.jpeg';
import styles from 'components/molecules/RocketsLunchListItem/RocketsLunchListItem.module.scss';
export const RocketsLunchListItem = ({
  flightNumber,
  flightName,
  date,
  rocketPhoto,
  rocketId,
  launchPadId,
}) => {
  const context = useContext(FlightsContext);
  return (
    <li className={styles.item}>
      <img
        className={styles.item__img}
        src={rocketPhoto ? rocketPhoto : nophoto}
        alt="rocket"
      />
      <div className={styles.item__wrapper}>
        <span className={styles.item__wrapper__number}># {flightNumber}</span>
        <h3 className={styles.item__wrapper__name}>{flightName}</h3>
        <span className={styles.item__wrapper__date}>{date}</span>
        <Link
          onClick={() => {
            context.getRocket(rocketId);
            context.getLaunchPad(launchPadId);
          }}
          to={`/details/${flightNumber}`}
        >
          <button
            className={styles.item__wrapper__button}
          >{`${'więcej >'}`}</button>
        </Link>
      </div>
    </li>
  );
};

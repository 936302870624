import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import { IconButton } from 'components/atoms/IconButton/IconButton';
import { FlightsContext } from 'providers/FlightsProvider';

import styles from 'components/organisms/DetailsPageHeader/DetailsPageHeader.module.scss';
export const DetailsPageHeader = ({ rocketNumber }) => {
  const context = useContext(FlightsContext);
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <Link to="/">
          <IconButton
            onClick={() => context.setActivePhotoIdx(0)}
            style={{
              padding: '0.2rem 0.5rem',
              borderRight: '1px solid #b7b7b7',
            }}
            icon={<TiArrowBack size={40} color={'rgba(48, 194, 213, 1)'} />}
          />
        </Link>
        <h3 className={styles.header__title}>
          Szczegóły lotu # {rocketNumber}
        </h3>
      </header>
    </section>
  );
};

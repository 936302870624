import React from 'react';
import { HomePage } from '../HomePage/HomePage';
import { DetailsPage } from '../DetailsPage/DetailsPage';
import { Routes, Route } from 'react-router-dom';
import { FlightsProvider } from 'providers/FlightsProvider';

import styles from 'components/templates/MainTemplate/MainTemplate.module.scss';
export const MainTemplate = () => {
  return (
    <div className={styles.container}>
      <FlightsProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/details/:number" element={<DetailsPage />} />
        </Routes>
      </FlightsProvider>
    </div>
  );
};

import React, { useContext } from 'react';
import { IconButton } from 'components/atoms/IconButton/IconButton';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { FlightsContext } from 'providers/FlightsProvider';

import styles from 'components/molecules/PhotosGallery/PhotosGallery.module.scss';
export const PhotosGallery = ({ rocketPhotos }) => {
  const context = useContext(FlightsContext);
  const galleyLength = rocketPhotos.length;
  return (
    <div className={styles.imagesWrapper}>
      {rocketPhotos.map((photo, index) => {
        return (
          index === context.activePhotoIdx && (
            <img
              key={index}
              className={styles.imagesWrapper__photo}
              src={photo}
              alt="rocket"
            />
          )
        );
      })}

      <div className={styles.imagesWrapper__slider}>
        <IconButton
          onClick={() => context.prevPhoto(galleyLength)}
          icon={<GrPrevious size={35} />}
        />
        <ul className={styles.imagesWrapper__slider__gallery}>
          {rocketPhotos.map((photo, index) => (
            <li
              onClick={() => context.setActivePhotoIdx(index)}
              key={photo}
              className={styles.sliderGalleryItem}
            >
              <img className={styles.gallery__photo} src={photo} alt="rocket" />
            </li>
          ))}
        </ul>
        <span className={styles.counter}>
          {context.activePhotoIdx + 1}/{rocketPhotos.length}
        </span>
        <IconButton
          onClick={() => context.nextPhoto(galleyLength)}
          icon={<GrNext size={35} />}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const FlightsContext = React.createContext({
  flights: [],
  activePhotoIdx: '',
  setActivePhotoIdx: () => {},
  filteredFlights: [],
  setFilteredFlights: () => {},
  currentRocket: {},
  arrayLength: '',
  currentLaunchPad: {},
  nextPhoto: () => {},
  prevPhoto: () => {},
  getRocket: () => {},
  getLaunchPad: () => {},
  filters: {},
  setFilters: () => {},
});

const rocketInitialState = {
  name: '',
  flickr_images: [],
};

const launchPadInitialState = {
  name: '',
};

const defaultFilters = {
  name: '',
  status: false,
  date: null,
};

export const FlightsProvider = ({ children }) => {
  const [flights, setFlights] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [activePhotoIdx, setActivePhotoIdx] = useState(0);
  const [currentRocket, setCurrentRocket] = useState(rocketInitialState);
  const [currentLaunchPad, setCurrentLaunchPad] = useState(
    launchPadInitialState
  );
  const [arrayLength, setArrayLength] = useState(20);
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    getLaunches();
  }, []);

  const getLaunches = async () => {
    try {
      const { data: launchData } = await axios.get(
        'https://api.spacexdata.com/v4/launches/'
      );
      const sortedArrayByDate = launchData.sort(
        (a, b) => new Date(b.date_local) - new Date(a.date_local)
      );
      setFlights(sortedArrayByDate);
      setFilteredFlights(sortedArrayByDate);
    } catch (e) {
      console.error(e);
    }
  };

  const getRocket = async (rocketId) => {
    try {
      const { data: rocketData } = await axios.get(
        `https://api.spacexdata.com/v4/rockets/${rocketId}/`
      );

      setCurrentRocket(rocketData);
    } catch (e) {
      console.log(e);
    }
  };

  const getLaunchPad = async (launchPadId) => {
    try {
      const { data: launchPadData } = await axios.get(
        `https://api.spacexdata.com/v4/launchpads/${launchPadId}/`
      );

      setCurrentLaunchPad(launchPadData);
    } catch (e) {
      console.log(e);
    }
  };

  const loadMoreLunches = () => {
    setArrayLength((prevState) => prevState + 20);
  };

  const nextPhoto = (length) => {
    setActivePhotoIdx(activePhotoIdx === length - 1 ? 0 : activePhotoIdx + 1);
  };

  const prevPhoto = (length) => {
    setActivePhotoIdx(activePhotoIdx === 0 ? length - 1 : activePhotoIdx - 1);
  };

  return (
    <FlightsContext.Provider
      value={{
        flights,
        setFlights,
        activePhotoIdx,
        setActivePhotoIdx,
        nextPhoto,
        prevPhoto,
        filteredFlights,
        setFilteredFlights,
        getLaunches,
        getRocket,
        currentRocket,
        setCurrentRocket,
        getLaunchPad,
        currentLaunchPad,
        setCurrentLaunchPad,
        arrayLength,
        setArrayLength,
        loadMoreLunches,
        filters,
        setFilters,
      }}
    >
      {children}
    </FlightsContext.Provider>
  );
};

import React from 'react';
import { Label } from 'components/atoms/Label/Label';
import { Input } from 'components/atoms/Input/Input';

import styles from 'components/molecules/CheckBoxField/CheckBoxField.module.scss';
export const CheckBoxField = ({ name, type, onChange, value, ...props }) => {
  return (
    <Label textLabel="Pokaż tylko udane loty" className={styles.container}>
      <Input
        styleInput={{
          width: '10%',
          transform: 'scale(1.7)',
          marginRight: '1rem',
        }}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        {...props}
      />
    </Label>
  );
};

import React from 'react';

import styles from 'components/atoms/Input/Input.module.scss';
export const Input = ({
  type,
  placeholder,
  onChange,
  styleInput,
  name,
  value,
  ...props
}) => {
  return (
    <input
      style={styleInput}
      className={styles.input}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      value={value}
      {...props}
    />
  );
};

import React from 'react';

import styles from 'components/atoms/Label/Label.module.scss';
export const Label = ({ textLabel, children, ...props }) => {
  return (
    <label className={styles.label} {...props}>
      {textLabel}
      {children}
    </label>
  );
};

import React from 'react';
import { DetailsInfoWrapper } from 'components/molecules/DetailsInfoWrapper/DetailsInfoWrapper';
import { PhotosGallery } from 'components/molecules/PhotosGallery/PhotosGallery';
import { DescriptionWrapper } from 'components/molecules/DescriptionWrapper/DescriptionWrapper';

import styles from 'components/organisms/DetailsPageDetailsWrapper/DetailsPageDetailsWrapper.module.scss';
export const DetailsPageDetailsWrapper = ({
  flightName,
  flightDate,
  flightState,
  flightCrew,
  rocketPhotos,
  description,
}) => {
  return (
    <section className={styles.detailsWrapper}>
      <DetailsInfoWrapper
        flightName={flightName}
        flightDate={flightDate}
        flightState={flightState}
        flightCrew={flightCrew}
      />
      <PhotosGallery rocketPhotos={rocketPhotos} />
      <DescriptionWrapper description={description} />
    </section>
  );
};

import { MainTemplate } from './components/templates/MainTemplate/MainTemplate';
import './Root.css';

function Root() {
  return (
    <div className="Root">
      <MainTemplate />
    </div>
  );
}

export default Root;

import React, { useContext } from 'react';
import { RocketsLunchListItem } from 'components/molecules/RocketsLunchListItem/RocketsLunchListItem';
import { FlightsContext } from 'providers/FlightsProvider';

import styles from 'components/organisms/RocketsLunchList/RocketsLunchList.module.scss';
export const RocketsLunchList = () => {
  const context = useContext(FlightsContext);
  return (
    <section className={styles.container}>
      <ul className={styles.list}>
        {context.filteredFlights.slice(0, context.arrayLength).map((flight) => (
          <RocketsLunchListItem
            key={flight.flight_number}
            flightNumber={flight.flight_number}
            flightName={flight.name}
            date={flight.date_local.slice(0, 10)}
            rocketPhoto={flight.links.flickr.original[0]}
            rocketId={flight.rocket}
            launchPadId={flight.launchpad}
          />
        ))}
      </ul>
      <button
        onClick={() => context.loadMoreLunches()}
        className={styles.button}
      >{`${'załaduj więcej>'}`}</button>
    </section>
  );
};

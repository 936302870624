import React, { useContext, useEffect } from 'react';
import { DetailsPageHeader } from 'components/organisms/DetailsPageHeader/DetailsPageHeader';
import { DetailsPageDetailsWrapper } from 'components/organisms/DetailsPageDetailsWrapper/DetailsPageDetailsWrapper';
import { useParams } from 'react-router-dom';
import { FlightsContext } from 'providers/FlightsProvider';

export const DetailsPage = () => {
  const context = useContext(FlightsContext);
  const { number } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredFlight = context.filteredFlights.filter(
    (flight) => flight.flight_number === parseInt(number)
  );

  return (
    <>
      <DetailsPageHeader rocketNumber={number} />
      {filteredFlight.map((flight) => (
        <DetailsPageDetailsWrapper
          key={flight.flight_number}
          flightName={flight.name}
          flightDate={flight.date_local.slice(0, 10)}
          flightState={flight.success ? 'Udany' : 'Nieudany'}
          flightCrew={flight.crew.length}
          rocketPhotos={flight.links.flickr.original.concat(
            context.currentRocket.flickr_images
          )}
          description={flight.details}
        />
      ))}
    </>
  );
};

import React, { useContext } from 'react';
import { Label } from 'components/atoms/Label/Label';
import { FlightsContext } from 'providers/FlightsProvider';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

import '../DateRangePickerField/Calendar.css';
import '../DateRangePickerField/DateRangePicker.css';
import styles from 'components/molecules/DateRangePickerField/DateRangePickerField.module.scss';
export const DateRangePickerField = ({ onChange }) => {
  const context = useContext(FlightsContext);

  return (
    <Label textLabel="Data lotu" className={styles.container}>
      <DateRangePicker
        name="date"
        onChange={onChange}
        value={context.filters.date}
        format="y-MM-dd"
        monthPlaceholder="MM"
        yearPlaceholder="RRRR"
        dayPlaceholder="DD"
        clearIcon={null}
        calendarIcon={
          <FaRegCalendarAlt color="rgba(48, 194, 213, 1)" size={20} />
        }
      />
    </Label>
  );
};
